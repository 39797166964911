.consulting-banner {
    padding: 80px 0px;
    border-bottom: 2px solid var(--foundation-violet);
}
.consulting-banner-grid {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    gap: 24px;
    max-width: 974px;
    width: 100%;
    margin: 0px auto;
}
.consulting-banner-right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 24px;
}
.consulting-banner-right h1 {
    color: var(--foundation-violet);
    text-align: right;
    font-family: var(--assistant);
    font-size: 56px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    letter-spacing: 4.48px;
    margin: 0;
}
.consulting-banner-right .black-btn {
    max-width: 278px;
    height: 59px;
}
/* ********************* consulting-detail-section ************************* */
.consulting-detail-section {
    padding: 112px 0px;
}
.consulting-detail-grid {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    gap: 80px;
}
.consulting-detail-left {
    max-width: 40%;
    width: 100%;
    
}
.consulting-detail-left ul {
    margin: 0;
    padding: 0;
}
.consulting-detail-left h6 {
    font-size: 24px;
    font-weight: 700;
    letter-spacing: .96px;
    line-height: 140%;
    color: var(--text-black);
    font-family: var(--assistant);
    font-style: normal;
    margin: 0;
    padding-bottom: 20px;
}
.consulting-detail-left li {
    color: var(--black);
    text-align: right;
    font-family: var(--assistant);
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
}
.consulting-detail-left li+li {
    padding-top: 10px;
}
.rtl .consulting-detail-left li {
    direction: rtl;
}
.consulting-detail-left .new-faq-list-section {
    margin: 0px auto !important;
    border: none;
    height: 458px;
    overflow: auto;
    padding-left: 15px;
}
.consulting-page .consulting-detail-left .new-faq-list .accordion-item {
    background: transparent;
}
.consulting-detail-right {
    max-width: 48%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 24px;
}
.consulting-detail-right h2 {
    color: #1E0F3C;
    text-align: right;
    font-family: var(--assistant);
    font-size: 56px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    letter-spacing: 4.48px;
    margin: 0;
}
.rtl .consulting-detail-right h2 {
    direction: rtl;
}
.consulting-detail-right p {
    color:  #685E7C;
    text-align: right;
    font-family: var(--assistant);
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin: 0;
    padding-top: 48px;
}
.rtl .consulting-detail-right p {
    direction: rtl;
}
.consulting-detail-right .black-btn {
    max-width: 278px;
    height: 59px;
}

.consulting-page .faq-section {
    background:  #E9E7EC;
}
.consulting-page .new-faq-list .accordion-item {
    background:  #E9E7EC;
}
/* ********************* exclusion-section ************************* */ 
.exclusion-section {
    background:  #E9E7EC;
    padding: 112px 0px;
}
.exclusion-title {
    display: flex;
    gap: 16px;
    justify-content: flex-end;
}
.rtl .exclusion-title {
    direction: rtl;
    justify-content: flex-start;
}
.exclusion-title h2 {
    color: #000;
    text-align: right;
    font-family: var(--assistant);
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    margin: 0;
}
.exclusion-list-section {
    padding-top: 24px;
    padding-bottom: 16px;
    border-bottom: 1px solid #000;
}
.exclusion-list-section ul {
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    list-style: none;
}
.exclusion-list-section li {
    width: 48%;
    color:  #000;
    text-align: right;
    font-family: var(--assistant);
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.exclusion-list-section li img {
    width: 20px;
    height: 20px;
}

/* **************** Blogs Section ********************** */

.blogs-section {
    padding: 112px 0px;
}
.blogs-title h2 {
    color: var(--black, #000);
    text-align: center;
    font-family: var(--assistant);
    font-size: 42px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    margin: 0;
}
.blogs-grid {
    padding-top: 80px;
    padding-bottom: 64px;
    display: grid;
    grid-template-columns: repeat(3,minmax(0,1fr));
    gap: 32px;
}
.blogs-box {
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px 3px 24px 0px rgba(0, 0, 0, 0.16);
}
.blogs-thumbnail img {
    max-width: 100%;
    width: 100%;
    height: 300px;
    object-fit: cover;
}
.blogs-content {
    padding: 41px 24px;
}
.tags-grid {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    justify-content: flex-end;
}
.tags-grid span {
    border-radius: 4px;
    background: #FFD7C8;
    color: #B76E54;
    font-family: var(--assistant);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    padding: 6px 16px;
}
.blogs-text {
    padding-top: 8px;
    padding-bottom: 24px;
}
.blogs-text h3 {
    color:  #000;
    text-align: right;
    font-family: var(--assistant);
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    margin: 0;
}
.blogs-text p {
    color:  #000;
    text-align: right;
    font-family: var(--assistant);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.36px;
    margin: 0;
    padding-top: 8px;
}
.blogger-info-grid {
    display: flex;
    gap: 16px;
}
.rtl .blogger-info-grid {
    direction: rtl;
}
.blogger-info-grid img {
    width: 48px;
    height: 48px;
    border-radius: 28px;
    object-fit: cover;
}
.blogger-info h6 {
    color:  #000;
    text-align: right;
    font-family: var(--assistant);
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    margin: 0;
}
.blogs-info {
    display: flex;
    gap: 8px;
}
.blogs-info p {
    color: #000;
    font-family: var(--roboto);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin: 0;
    position: relative;
}
.blogs-info p:first-child {
    direction: ltr;
}
.blogs-info p.estimate {
    margin-right: 8px;
}

.blogs-info p.estimate:before {
    content: "";
    position: absolute;
    width: 5px;
    height: 5px;
    border-radius: 100%;
    background: #000;
    top: 40%;
    right: -12px;
}
.blogs-cta {
    display: flex;
    justify-content: center;
}
.blogs-cta .border-btn {
    max-width: 163px;
    height: 59px;
}
.consulting-detail-left .new-faq-list-section::-webkit-scrollbar-track {
    
    background-color: transparent;
  }
  
  .consulting-detail-left .new-faq-list-section::-webkit-scrollbar {
    width: 3px;
    background-color: transparent;
  }
  
  .consulting-detail-left .new-faq-list-section::-webkit-scrollbar-thumb {
    background-color: #f89b29;
  }

/*************** Media Query ******************/

@media screen and (max-width: 1024px) { 
    .blogs-grid {
        grid-template-columns: repeat(2,minmax(0,1fr));
    }
    .consulting-detail-grid {
        gap: 24px;
    }
}
@media screen and (max-width: 991px) { 
    .consulting-banner {
        padding: 60px 0px;
    }
    .consulting-detail-section {
        padding: 60px 0px;
    }
    .consulting-detail-right h2 {
        font-size: 46px;
    }
    .consulting-detail-grid {
        max-width: 450px;
        margin: 0px auto;
    }
    .consulting-detail-left {
        max-width: 100%;
        order: 2;
        padding-right: 20px;
    }
    .consulting-detail-right {
        max-width: 100%;
    }
    .exclusion-section {
        padding: 60px 0px;
    }
    .blogs-section {
        padding: 60px 0px;
    }
}
@media screen and (max-width: 767px) { 
    .consulting-banner-left {
        width: 100%;
        text-align: center;
    }
    .consulting-banner-right {
        width: 100%;
        align-items: center;
    }
    .consulting-banner-right h1 {
        text-align: center;
        font-size: 38px;
        letter-spacing: 3.04px;
    }
    .consulting-banner-right .black-btn {
        max-width: 342px;
    }
    .consulting-detail-right h2 {
        font-size: 32px;
        letter-spacing: 2.56px;
    }
    .consulting-detail-right p {
        font-size: 18px;
    }
    .exclusion-list-section ul {
        gap: 8px;
    }
    .exclusion-list-section li {
        width: 100%;
    }
    .consulting-page .new-faq-list-section {
        margin: 24px 0px;
    }
    .blogs-title h2 {
        text-align: center;
        font-size: 24px;
    }
    .blogs-text h3 {
        font-size: 18px;
    }
    .blogs-text p {
        font-size: 16px;
    }
   
    .blogs-grid {
        grid-template-columns: repeat(1,minmax(0,1fr));
    }
    .consulting-detail-grid {
        max-width: 100%;
    }
    .consulting-detail-left h6 {
        font-size: 20px;
        padding-bottom: 15px;
    }
    .consulting-detail-left li {
        font-size: 16px;
    }
}