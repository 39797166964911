.contact-page {
    padding: 80px 0px;
}
.contact-title h2 {
    color: #1E0F3C;
    text-align: center;
    font-family: var(--assistant);
    font-size: 42px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: 3.36px;
    margin: 0;
    padding-bottom: 20px;
}
.contact-title p {
    margin: 0;
    color: var(--text-black);
    text-align: center;
    font-family: var(--assistant);
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.36px;
}
.contact-title a {
    font-size: 24px;
    line-height: 22px;
    font-weight: 500;
    margin: 0;
    color: #1E0F3C;
    font-family: var(--assistant);
}
.contact-form {
    max-width: 560px;
    width: 100%;
    margin: 0px auto;
    box-shadow: 5px 5px 15px -1px rgba(0,0,0,0.75);
    padding: 30px;
    margin-top: 40px;
    border-radius: 20px;
}

.contact-form .spinner-border {
    --bs-spinner-border-width: 0.1em;
}
p.text-danger {
    margin: 0;
    padding-top: 3px;
}

.rtl .contact-page {
    direction: rtl;
}
.rtl .contact-page .react-tel-input  {
    direction: ltr;
}
.contact-page .new-contact-form {
    background: rgb(233 231 236 / 36%);
    border-radius: 4px;
    padding: 30px;
    /* box-shadow: 5px 5px 5px 5px rgba(0,0,0,.1); */
}

/*************** Media Query ******************/

@media screen and (max-width:991px) { 
    .contact-page {
        padding: 60px 0px;
    }
}

@media screen and (max-width:767px) { 
    .contact-title h2 {
        letter-spacing: 1.92px;
    }
}
