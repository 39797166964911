.doctor-banner {
    padding: 40px 0px;
    border-bottom: 2px solid #000;
}
.doctor-banner-grid {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
}
.doctor-banner-right h1 {
    color: var(--foundation-violet);
    text-align: right;
    font-family: var(--assistant);
    font-size: 56px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    letter-spacing: 4.48px;
    margin: 0;
}
.doctor-profile-section {
    padding-top: 160px;
    padding-bottom: 112px;
}
.doctor-avtar {
    display: flex;
    justify-content: center;
    margin-top: -285px;
}
.doctor-avtar img {
    border-radius: 250px;
    border: 2px solid #000;
    width: 250px;
    height: 250px;
    object-fit: cover;
    background: #fff;
}
.doctor-degree {
    padding-top: 38px;
}
.doctor-degree h2 {
    color: var(--foundation-violet);
    text-align: center;
    font-family: var(--assistant);
    font-size: 42px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    letter-spacing: 3.36px;
    margin: 0;
}
.doctor-degree h3 {
    color: var(--foundation-violet);
    text-align: center;
    font-family: var(--assistant);
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    letter-spacing: 2.56px;
    max-width: 350px;
    margin: 0px auto;
}
.doctor-spailciality-grid {
    padding-top: 24px;
}
.doctor-spailciality {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 24px;
    padding-bottom: 24px;
}
.doctor-spailciality-grid .black-btn {
    max-width: 278px;
    height: 59px;
    margin: 0px auto;
}
.doctor-additional {
    padding-top: 40px;
    max-width: 626px;
    width: 100%;
    margin: 0px auto;
}
.doctor-additional p {
    color: var(--foundation-violet);
    font-family: var(--assistant);
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin: 0;
    text-align: center;
}
.rtl .doctor-additional p {
    direction: rtl;
}

.doctor-testimonial-title h2 {
    color: var(--text-black);
    text-align: center;
    font-family: var(--assistant);
    font-size: 42px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: 3.36px;
    margin: 0;
}
.doctor-testimonial .star-grid {
    justify-content: flex-end;
}
.testimonial-user-info {
    display: flex;
    gap: 16px;
    align-items: center;
}
.rtl .testimonial-user-info {
    direction: rtl;
}
.testimonial-user-left img {
    width: 56px;
    height: 56px;
    border-radius: 56px;
    object-fit: cover;
}
.testimonial-user-right h6 {
    color: var(--text-black);
    text-align: right;
    font-family: var(--assistant);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    margin: 0;
}
.testimonial-user-right p {
    color: var(--text-black);
    text-align: right;
    font-family: var(--assistant);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin: 0;
}
.doctor-testimonial-section .slick-prev, .doctor-testimonial-section .slick-next {
    display: none !important;
}
.related-doctor-section {
    padding: 112px 0px;
}
.related-doctor-title h2 {
    color: var(--text-black);
    text-align: center;
    font-family: var(--assistant);
    font-size: 42px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    margin: 0;
}
.related-doctor-grid {
    display: grid;
    grid-template-columns: repeat(3,minmax(0,1fr));
    gap: 24px;
    padding-top: 80px;
}
.related-doctor-avtar {
    text-align: center;
}
.related-doctor-avtar img {
    width: 120px;
    height: 120px;
    border-radius: 60px;
    object-fit: cover;
}
.related-doctor-box .doctor-spailciality {
    padding-top: 16px;
    gap: 16px;
    padding-bottom: 8px;
}
.related-doctor-box .doctor-spailciality .pink-btn {
    max-width: 128px;
}
.related-doctor-detail h5 {
    color: var(--text-black);
    text-align: center;
    font-family: var(--assistant);
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    margin: 0;
}
.related-doctor-detail h6 {
    color: var(--text-black);
    text-align: center;
    font-family: var(--assistant);
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    margin: 0;
}
.related-doctor-detail p {
    color:  #685E7C;
    text-align: center;
    font-family: var(--roboto);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin: 0;
    padding-top: 8px;
}

/*************** Media Query ******************/

@media screen and (max-width: 1024px) { 
    .doctor-banner {
        padding-top: 60px;
        padding-bottom: 80px;
    }
 }
 @media screen and (max-width: 991px) { 
    .doctor-avtar {
        margin-top: -170px;
    }
    .doctor-avtar img {
        width: 160px;
        height: 160px;
    }
    .doctor-profile-section {
        padding-top: 80px;
        padding-bottom: 60px;
    }
    .doctor-degree {
        padding-top: 20px;
    }
    .doctor-additional {
        padding-top: 24px;
    }
    .doctor-testimonial-section {
        padding: 60px 0px;
    }
    .related-doctor-section {
        padding: 60px 0px;
    }
 }
 @media screen and (max-width: 767px) { 
    .doctor-banner-left {
        width: 100%;
        text-align: center;
    }
    .doctor-banner-left img {
        width: 150px;
        height: 125px;
    }
    .doctor-banner-right {
        width: 100%;
    }
    .doctor-banner-right h1 {
        text-align: center;
        font-size: 32px;
        letter-spacing: 2.56px;
    }
    .doctor-avtar {
        margin-top: -145px;
    }
    .doctor-avtar img {
        width: 120px;
        height: 120px;
    }
    .doctor-degree h2 {
        font-size: 32px;
        letter-spacing: 2.56px;
    }
    .doctor-degree h3 {
        font-size: 18px;
        letter-spacing: 1.44px;
        max-width: 225px;
    }
    .doctor-additional p {
        font-size: 18px;
    }
    .doctor-testimonial-title h2 {
        font-size: 24px;
        letter-spacing: 1.92px;
    }
    .doctor-testimonial.testimonial-box {
        padding: 32px;
        gap: 32px;
    }
    .doctor-testimonial .star-grid {
        justify-content: center;
    }
    .doctor-testimonial .testimonial-text h6 {
        font-size: 14px;
    }
    .testimonial-user-info {
        justify-content: center;
    }
    .related-doctor-grid {
        grid-template-columns: repeat(1,minmax(0,1fr));
    }
    .related-doctor-title h2 {
        font-size: 40px;
    }
    .related-doctor-box .doctor-spailciality .pink-btn {
        max-width: 118px;
    }
 }


