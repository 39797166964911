.partner-banner-section {
    background: url("../../assets/images/new/partner/bg.svg") no-repeat #1E0F3C;
    width: 100%;
    height: 100%;
    padding: 70px 0px;
    background-position: center;
    background-size: cover;
    text-align: center;
}

@media screen and (max-width: 991px) {
    .partner-banner-section {
        padding: 45px 0px;
    }
}