.new-footer {
    padding: 80px 0px;
    background: var(--foundation-violet);
    padding-bottom: 100px;
}
.rtl .new-footer {
    direction: rtl;
}
.new-top-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}
.footer-left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 600px;
    width: 100%;
}

.new-footer-social {
    padding-top: 32px;
    padding-bottom: 24px;
}
.new-footer-social ul {
    align-items: center;
    display: flex;
    gap: 12px;
    justify-content: flex-start;
    list-style-type: none;
    margin: 0;
    padding: 0;
    width: 100%;
}
.rtl .new-footer-social ul {
    direction: ltr;
}
.new-address {
    display: flex;
    flex-direction: column;
}
.new-address h6 {
    color: var(--foundation-btn-color);
    text-align: right;
    font-family: var(--assistant);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    margin: 0;
}
.new-address a {
    color: var(--foundation-btn-color);
    text-align: right;
    font-family: var(--roboto);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    text-decoration-line: underline;
}
.new-footer-menu {
    max-width: calc(100% - 664px);
    width: 100%;
}
.new-footer-links {
    display: flex;
    
}
ul.new-links-list {
    margin: 0;
    padding: 0;
    color: var(--foundation-btn-color);
    text-align: right;
    font-family: var(--assistant);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    max-width: 312px;
    width: 100%;
}
ul.new-links-list  li {
    list-style-type: none;
    padding-top: 12px;
}
.new-links-list a {
    color: var(--foundation-btn-color);
    font-family: var(--assistant);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; 
    text-decoration: none;
}

.new-bottom-footer {
    position: relative;
    margin-top: 24px;
    padding-top: 24px;
    border-top: 1px solid var(--foundation-btn-color);
}
.new-footer-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}
.footer-grid-left p {
    color: var(--foundation-btn-color);
    font-family: var(--roboto);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin: 0;
}
.footer-grid-left a {
    color: var(--foundation-btn-color);
    font-family: var(--roboto);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    text-decoration-line: underline;
}
.footer-grid-left a + a {
    margin-left: 24px;
}
.footer-grid-center p {
    color: var(--foundation-btn-color);
    font-family: var(--roboto);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin: 0;
}
.footer-grid-right p {
    color: var(--foundation-btn-color);
    font-family: var(--roboto);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin: 0;
}
.rtl .footer-grid-right p {
    direction: ltr;
}
.new-middle-footer {
    padding-top: 24px;
}
.new-middle-footer p {
    color: var(--foundation-btn-color);
    font-family: var(--assistant);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin: 0;
    text-align: center;
}
/*************** Media Query ******************/

@media screen and (max-width: 1024px) {
    .footer-left {
        max-width: 280px;
    }
    .new-footer-menu {
        max-width: calc(100% - 312px);
    }
}

@media screen and (max-width: 991px) {
    .new-footer {
        padding-bottom: 80px;
    }
    .footer-left {
        max-width: 100%;
    }
    .new-footer-menu {
        max-width: 100%;
    }
    .new-top-footer {
        row-gap: 40px;
    }
    .new-footer-links {
        row-gap: 24px;
    }
    ul.new-links-list {
        max-width: 48%;
    }
    .new-footer-grid {
        row-gap: 8px;
    }
    .footer-grid-left, .footer-grid-right, .footer-grid-center {
        width: 100%;
        text-align: center;
    }
    .footer-grid-left a + a {
        margin-left: 40px;
    }    
}
@media screen and (max-width: 767px) { 
    .new-footer-links {
        flex-wrap: wrap; 
    }
}
@media screen and (max-width: 480px) { 
    ul.new-links-list {
        max-width: 100%;
    }
}