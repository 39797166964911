@import url('https://fonts.googleapis.com/css2?family=Assistant:wght@200;300;400;500;600;700;800&display=swap');
.support-chat {
    background: transparent;
    bottom: 0px;
    right: 40px;
    color: #fff;
    position: fixed;
    text-align: center;
    transition: all .5s;
    width: 90px;
    height: 90px;
    z-index: 9;
  }
  .rtl .support-chat {
    left: 40px;
    right: unset;
    bottom: 15px;
  }
  .support-chat .btn-primary {
    padding: 0;
    background: transparent;
    border: none;
  }
  .support-chat img {
    width: 90px;
    height: 90px;
  }
  .support-chat .btn-primary:hover{
    background: transparent;
  }
  .support-chat .btn:first-child:active {
    background: transparent;
    border-color: transparent;
  }
  body.show-enable-toolbar .support-chat {
    bottom: 250px;
    transition: all .5s;
  }
  .support-chat a img {
    width: 100%;
  }
  .floating { 
    animation-name: floating;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }
  @keyframes floating {
    0% { transform: translate(0,  0px); }
    50%  { transform: translate(0, 8px); }
    100%   { transform: translate(0, -0px); }   
  }
  .support-modal {
    bottom: 70px;
    right: 140px;
    color: #fff;
    position: fixed;
    text-align: center;
    transition: all .5s;
    max-width: 204px;
    width: 100%;
    border-radius: 16px 16px 0px 16px;
    background: #FFF;
    box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.16);
    padding: 16px;
    z-index: 9;
  }
  .rtl .support-modal {
    left: 140px;
    right: unset;
    border-radius: 16px 16px 16px 0px;
  }
  .support-title h6 {
    color: #685E7C;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    margin: 0;
    padding-bottom: 4px;
    font-family: 'Assistant', sans-serif;
  }
  .support-title p {
    color: #000;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    margin: 0;
    font-family: 'Assistant', sans-serif;
  }
  .support-grid {
    display: flex;
    gap: 32px;
    padding-top: 16px;
  }
  .support-grid span {
    cursor: pointer;
  }
  .alert-modal-grid {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .alert-modal-grid h6 {
    margin: 0;
    font-size: 20px;
    color: #14427d;
    font-family: 'Assistant', sans-serif;
    font-weight: 500;
  }
  .alert-modal-grid a {
    margin: 0;
    font-size: 20px;
    color: #14427d;
    font-family: 'Assistant', sans-serif;
    font-weight: 600;
  }
  .mobile-view {
    display: none;
  }
  
  /* for mobile */
@media screen and (max-width: 991px) { 
    .support-chat {
      width: 60px;
      height: 60px;
      right: 25px;
    }
    .desktop-view {
      display: none;
    }
    .mobile-view {
      display: block;
    }
    .rtl .support-chat {
      left: 25px;
    }
    .rtl .support-modal {
      left: 85px;
    }
    .support-modal {
        right: 85px;
    }
    .support-chat img {
      width: 60px;
      height: 60px;
    }
  }
  @media screen and (max-width: 767px) {
    .support-chat {
      width: 50px;
      height: 50px;
      right: 25px;
    }
    .rtl .support-modal {
      left: 85px;
    }
    .support-modal {
        right: 85px;
    }
    .support-chat img {
      width: 50px;
      height: 50px;
    }
    body.show-enable-toolbar .support-chat {
      bottom: 110px; 
    }
  }