.terms-pages {
    padding: 80px 0px;
}
.terms-pages h2 {
    color: #1E0F3C;
    text-align: center;
    font-family: var(--assistant);
    font-size: 42px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: 3.36px;
    margin: 0;
    padding-bottom: 20px;
}
.terms-grid {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
}
.terms-grid h3 {
    color: var(--text-black);
    font-family: var(--assistant);
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    letter-spacing: 0.96px;
    margin: 0;
}
.terms-grid p {
    text-align: right;
    font-family: var(--assistant);
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin: 0;
}
.terms-grid ol, .terms-grid ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    margin: 0;
    padding-right: 0;
}
.rtl .terms-grid ol, .rtl .terms-grid ul {
    padding-left: 0;
    padding-right: 2rem;
}
.terms-grid li {
    font-family: var(--assistant);
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin: 0;
}
.terms-grid li::marker {
    font-weight: 700;
}
.terms-grid a {
    font-weight: 500;
    margin: 0;
    color: var(--text-black);
}
.rtl .terms-grid {
    direction: rtl;
}

/*************** Media Query ******************/

@media screen and (max-width:991px) { 
    .terms-pages {
        padding: 60px 0px;
    }
}
@media screen and (max-width:767px) { 
    .terms-pages h2 {
        letter-spacing: 1.92px;
    }
    .terms-grid h3 {
        font-size: 22px;
    }
    .terms-grid p {
        font-size: 18px;
    }
    .terms-grid {
        gap: 10px;
    }
    .terms-grid li {
        font-size: 18px;
    }
}