.loader_page {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    margin: 0;
  }
  .spinner {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    position: relative;
  }
  .spinner_round {
    width: 50px;
    --b: 8px;
    aspect-ratio: 1;
    border-radius: 50%;
    padding: 1px;
    background: conic-gradient(#0000 10%, #4c70f0) content-box;
    -webkit-mask: repeating-conic-gradient(
        #0000 0deg,
        #000 1deg 20deg,
        #0000 21deg 36deg
      ),
      radial-gradient(
        farthest-side,
        #0000 calc(100% - var(--b) - 1px),
        #000 calc(100% - var(--b))
      );
    -webkit-mask-composite: destination-in;
    mask-composite: intersect;
    animation: s4 1s infinite steps(10);
  }
  @keyframes s4 {
    to {
      transform: rotate(1turn);
    }
  }