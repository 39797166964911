.new-home {
    overflow-x: hidden;
}
.react-tel-input{
   direction: ltr !important;
}
.new-hero-grid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 80px 0px;
}
.new-hero-left {
    max-width: 54.4%;
    width: 100%;
    border-right: 1px solid #000;
    padding-right: 20px;
}
.new-hero-right {
    max-width: 43.8%;
    width: 100%;
}
.hero-main-heading h1 {
    color: #1E0F3C;
    text-align: right;
    font-family: var(--assistant);
    font-size: 56px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    letter-spacing: 4.48px;
    max-width: 420px;
    width: 100%;
    margin: 0;
    margin-left: auto;
}
.hero-main-heading h1 span {
    font-weight: 700;
}
.rtl .hero-main-heading h1 {
    direction: rtl;
}
.hero-main-heading p {
    color:  #685E7C;
    font-family: var(--assistant);
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin: 0;
    padding: 24px 0px;
}
.rtl .hero-main-heading p {
    direction: rtl;
}
.hero-main-heading p span {
    font-weight: 700;
}
.search-input {
    position: relative;
}
.search-input-form {
    display: flex;
    position: relative;
}
.search-input-form .black-btn {
    border-radius: 0px 8px 8px 0px !important;
    max-width: 66px;
    height: 66px;
    padding: 24px !important;
}
.rtl .search-input-form .black-btn {
    border-radius: 8px 0px 0px 8px !important;
}
.search-input-form .black-btn.btn:disabled {
    opacity: 1;
}
.search-input-form .form-control {
    border-radius: 8px 0px 0px 8px;
    border: 2px solid #000;
    background: #FFF;
    border-right: none;
    color: var(--text-black);
    font-family: var(--assistant);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; 
    padding: 0px 16px;
    padding-left: 56px;
    padding-right: 16px;
}
.rtl .search-input-form .form-control  {
    text-align: right;
    padding-right: 56px;
    padding-left: 16px;
    border-radius: 0px 8px 8px 0px;
    border: 2px solid #000;
    border-left: none;
}

.search-input-form .form-control:focus {
    box-shadow: none;
}
.search-input-form .form-control::placeholder {
    color: var( --placeholder-color);
}
img.search-icon {
    position: absolute;
    left: 16px;
    top: 50%;
    transform: translate(0px, -50%);
}
.rtl img.search-icon {
    right: 16px;
}
.rtl .search-input-form {
    direction: rtl;
}
.search-result-section {
    position: absolute;
    width: 100%;
    box-shadow: 0px 3px 24px 0px rgba(0, 0, 0, 0.16);
    padding: 16px;
    border: 2px solid #000;
    border-radius: 8px;
    top: 72px;
    min-height: 20px;
    max-height: 200px;
    overflow-y: auto;
    z-index: 9;
    background: #fff;
}
.search-result-section ul {
    margin: 0;
    padding: 0;
    list-style: none;
}
.search-result-section li {
    color: var(--text-black);
    font-family: var(--assistant);
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    cursor: pointer;
}
.search-result-section::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 50px;
}
  
.search-result-section::-webkit-scrollbar {
    width: 7px;
    background-color: #E9E7EC;
    border-radius: 50px;
}
  
.search-result-section::-webkit-scrollbar-thumb {
    background: linear-gradient(343deg, #FF0F7B 0%, #F89B29 100%);
    border-radius: 50px;
}
.hero-cta-section {
    padding-top: 48px;
    display: flex;
}
.hero-cta-section .black-btn {
    max-width: 229px;
    height: 59px;
    
}
.rtl .hero-cta-section {
    justify-content: flex-end;
}
/* ************************************** UTM-banner ****************************************** */ 
.utm-banner-section {
    background: #E9E7EC;
    text-align: center;
}
.utm-banner-grid {
    padding: 2px 0px;
}
.rtl .utm-banner-grid {
    direction: rtl;
}
.utm-banner-section span {
    color: #1E0F3C;
    text-align: center;
    font-family: var(--assistant);
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: 2.88px;
    cursor: pointer;
}

/* ************************************** our-feature ****************************************** */

.our-feature {
    padding: 112px 0px;
    border-top: 2px solid  var(--foundation-violet);
    border-bottom: 2px solid  var(--foundation-violet);
}
.feature-main-grid {
    max-width: 1042px;
    width: 100%;
    margin: 0px auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 80px;
}
.feature-title h2 {
    color: #1E0F3C;
    font-family: var(--assistant);
    font-size: 42px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 50.4px */
    letter-spacing: 3.36px;
    margin: 0;
    text-align: center;
} 
.feature-title h2 span {
    font-weight: 700;
}
.features-grid {
    display: grid;
    grid-template-columns: repeat(3,minmax(0,1fr));
    column-gap: 32px;
    row-gap: 80px;
}
.rtl .features-grid {
    direction: rtl;
}
.features-box {
    text-align: center;
}
.features-box h3 {
    color: var(--text-black);
    text-align: center;
    font-family: var(--assistant);
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 33.6px */
    letter-spacing: 0.96px;
    margin: 0;
    padding-top: 32px;
}
.features-box p {
    color: var(--text-black);
    text-align: center;
    font-family: var(--assistant);
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 27px */
    letter-spacing: 0.36px;
    margin: 0;
}
.feature-cta {
    display: flex;
    justify-content: center;
}
.feature-cta .black-btn {
    max-width: 229px;
    height: 59px;
    font-size: 18px !important;
    font-weight: 700 !important;
}
 .stats-section {
    border-bottom: 2px solid #1E0F3C;
    background:  #E9E7EC;
    padding: 112px 0px;
}
.stats-main-grid {
    padding-bottom: 80px;
}
.stats-title h2 {
    color: #1E0F3C;
    font-family: var(--assistant);
    font-size: 42px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: 3.36px;
    text-align: center;
    margin: 0;
}
.stats-title h2 span {
    font-weight: 700;
}
.rtl .stats-title h2 {
    direction: rtl;
}
.stats-grid {
    padding-top: 80px;
    /* display: grid;
    grid-template-columns: repeat(4,minmax(0,1fr)); */
    display: flex;
    justify-content: center;
    gap: 32px;
    max-width: 640px;
    width: 100%;
    margin: 0px auto;
}
.stats-box {
    text-align: center;
    min-width: 304px;
}
.stats-box h3 {
    color: var(--text-black);
    text-align: center;
    font-family: var(--assistant);
    font-size: 56px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 2.24px;
    margin: 0;
}
.stats-box p {
    color: var(--text-black);
    text-align: center;
    font-family: var(--assistant);
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    letter-spacing: 0.48px;
    margin: 0;
    padding: 6px 0px;
}
.stats-box h4 {
    color: var(--text-black);
    text-align: center;
    font-family: var(--assistant);
    font-size: 42px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; 
    letter-spacing: 0.84px;
    margin: 0;
    direction: rtl;
}
.reel-grid {
    padding-top: 10px;
}

/* ************************************** how works section ****************************************** */
.how-works {
    padding: 112px 0px;
}
.how-works-grid {
    padding: 80px 0px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.how-works-left img {
    border-radius: 8px;
}
.how-works-right {
    display: flex;
    flex-direction: column;
    gap: 40px;
}
.how-works-box {
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px 3px 24px 0px rgba(0, 0, 0, 0.16);
    padding: 16px 0px;
}
.how-works-box-grid {
    display: flex;
    gap: 24px;
    padding-right: 16px;
    position: relative;
}
.how-works-box-grid::after {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    width: 2px;
    background: linear-gradient(343deg, #FF0F7B 0%, #F89B29 100%);
    height: 100%;
}
.how-works-box-left h6 {
    color: #FFF;
    font-family: var(--roboto);
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%; 
    margin: 0;
    background: #1E0F3C;
    width: 48px;
    height: 48px;
    border-radius: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
.how-works-box-right {
    max-width: 528px;
    width: 100%;
}
.how-works-box-right h6 {
    color: var(--text-black);
    text-align: right;
    font-family: var(--assistant);
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%; 
    margin: 0;
    padding-bottom: 16px;
}
.how-works-box-right p {
    color: var(--text-black);
    text-align: right;
    font-family: var(--assistant);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin: 0;
    max-width: 528px;
    width: 100%;
}
.rtl .how-works-box-right p {
    direction: rtl;
}
/* ************************************** medicine logo section ****************************************** */
.medicine-logo-section {
    padding: 80px 0px;
    position: relative;
}
.medicine-logo-section::before {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    background: linear-gradient(343deg, #FF0F7B 0%, #F89B29 100%);
    height: 8px;
}
.medicine-setion-title {
    text-align: center;
}
.medicine-setion-title h2 {
    color: var(--text-black);
    font-family: var(--assistant);
    font-size: 42px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: 3.36px;
    margin: 0;
    padding-bottom: 8px;
}
.rtl .medicine-setion-title h2 {
    direction: rtl;
}
.medicine-setion-title h2 span {
    font-weight: 700;
}
.medicine-setion-title p {
    color: var(--text-black);
    font-family: var(--assistant);
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    margin: 0;
}
.rtl .medicine-setion-title p {
    direction: rtl;
}
.medicine-slider-section {
    padding-top: 48px;
    padding-bottom: 80px;
}

.medicine-slider-logo {
    padding: 0px 50px;
    display: flex !important;
    justify-content: center;
    align-items: center;
    min-height: 200px;
}

.medicine-slider-logo img {
    filter: grayscale(1);
    transition: 0.5s all;
    width: 100%;
    height: 140px;
    object-fit: contain;
}

.medicine-slider-logo:hover img {
    filter: unset;
    scale: 1.05;
}
.medicine-slider-section .slick-center .medicine-slider-logo img {
    filter: unset;
    scale: 1.05;
}
.medicine-slider-section .slick-prev, .medicine-slider-section .slick-next {
    display: none;
}
/* ************************************** testimonial section ****************************************** */
.testimonial-section {
    padding: 112px 0px;
    border-top: 2px solid #000;
    border-bottom: 2px solid #000;
}
.testimonial-title h2 {
    color: #1E0F3C;
    text-align: center;
    font-family: var(--assistant);
    font-size: 42px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: 3.36px;
    margin: 0;
}
.testimonial-grid {
    gap: 32px;
    padding: 80px 0px;
    justify-content: center;
    display: grid;
    grid-template-columns: repeat(3,minmax(0,1fr));
}
.rtl .testimonial-grid {
    direction: rtl;
}
.testimonial-sub-grid {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 32px;
}
.testimonial-box {
    border-radius: 16px;
    background: var(--foundation-btn-color);
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding: 32px;
    max-width: 416px;
    width: 100%;
    height: fit-content;
}
.testimonial-box.first-line {
    height: 492px;
    position: relative;
} 
.testimonial-box.second-line {
    height: 303px;
    position: relative;
}
.testimonial-box.first-line  .testimonial-name, .testimonial-box.second-line .testimonial-name {
    position: absolute;
    bottom: 32px;
    width: 100%;
    /* max-width: 100%; */
    left: 50%;
    transform: translateX(-50%);
}
.star-grid {
    display: flex;
    justify-content: center;
    gap: 4px;
}
.testimonial-text h6 {
    color: var(--text-black);
    text-align: right;
    font-family: var(--assistant);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin: 0;
}
.testimonial-name {
    max-width: 165px;
    width: 100%;
    margin: 0px auto;
}
.testimonial-name h6 {
    color: var(--text-black);
    text-align: right;
    font-family: var(--assistant);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    margin: 0;
}
.testimonial-name p {
    color: var(--text-black);
    text-align: right;
    font-family: var(--assistant);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin: 0;
}

/* ************************************** Expert section ****************************************** */
.new-our-expert {
    padding: 112px 0px;
}
.new-expert-title h2 {
    color: var(--text-black);
    text-align: center;
    font-family: var(--assistant);
    font-size: 42px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: 3.36px;
    max-width: 560px;
    width: 100%;
    margin: 0px auto;
}
.new-expert-title h2 span {
    font-weight: 700;
}
.rtl .new-expert-title h2 {
    direction: rtl;
}
.new-expert-list ul {
    max-width: 539px;
    width: 100%;
    margin: 0px auto;
    padding: 0;
    padding-top: 40px;
    list-style: none;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    column-gap: 16px;
    row-gap: 8px;
}
.rtl .new-expert-list ul {
    direction: rtl;
}
.new-expert-list li {
    width: calc(100% / 3 - 16px);
    display: flex;
    justify-content: center;
    gap: 8px;
    color: var(--text-black);
    text-align: right;
    font-family: var(--assistant);
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
}
.new-expert-slider {
    padding-top: 40px;
    padding-bottom: 48px;
}
.new-expert-profile {
    position: relative;
}
.new-expert-profile:before {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    border-radius: 8px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 40.63%, rgba(30, 15, 60, 0.50) 78.12%);
}
.new-expert-profile {
    height: 371px;
    
}
.new-expert-profile img {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    background: #EDEDED;
    object-fit: contain;
}
.new-expert-content {
    position: absolute;
    bottom: 0;
    padding: 24px;
    width: 100%;
    right: 0;
}
.new-expert-content h6 {
    color: #FFF;
    font-family: var(--assistant);
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; 
    margin: 0;
}
.new-expert-content p {
    color: #FFF;
    font-family: var(--assistant);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    margin: 0;
}
.new-expert {
    padding: 0px 16px ;
    height: 100%;
}
.new-expert-slider .slick-prev, .new-expert-slider .slick-next {
    border-radius: 50px;
    border: 1px solid #000;
    width: 56px;
    height: 56px;
    z-index: 9;
}
.new-expert-slider .slick-prev {
    background: url("../../assets//images/new/left-arrow.svg") no-repeat center #FFF;
    left: -15px;
}
.new-expert-slider .slick-next {
    background: url("../../assets//images/new/right-arrow.svg") no-repeat center #FFF;
    right: -15px;
}
.new-expert-slider .slick-prev:before , .new-expert-slider .slick-next:before {
    content: unset;
}
.new-expert-slider .slick-dots {
    bottom: -48px;
}
.new-our-expert .feature-cta, .doctor-testimonial-section .feature-cta {
    padding-top: 40px;
}
.new-expert-slider .slick-dots li button:before {
    font-size: 8px;
    color:  #8D8D8D;
    opacity: 1;
}
.new-expert-slider .slick-dots li.slick-active button:before {
    color:  #000;
}

/* ************************************** FAQ section ****************************************** */
.faq-section {
    padding: 112px 0px;
}
.new-faq-title h2 {
    color: var(--text-black);
    text-align: center;
    font-family: var(--assistant);
    font-size: 42px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: 3.36px;
    margin: 0;
    padding-bottom: 8px;
}
.new-faq-title p {
    color: var(--text-black);
    text-align: center;
    font-family: var(--assistant);
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin: 0;
}
.new-faq-list-section {
    margin: 80px auto;
    display: flex;
    flex-direction: column;
    max-width: 768px;
    width: 100%;
    border-top: 1px solid #000;
}
.new-faq-list h6 {
    font-size: 24px;
    font-weight: 700;
    color: #14427d;
    margin: 0;
    border-bottom: 1px solid #d2dadd;
    padding-bottom: 16px;
}
.new-faq-list .accordion-button {
    column-gap: 50px;
    padding: 0px;
    background: transparent;
    color: var(--text-black);
    text-align: right;
    font-family: var(--assistant);
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
}
.new-faq-list .accordion-button:not(.collapsed) {
    box-shadow: none;
    color: var(--text-black);
}

.new-faq-list .accordion-button:focus {
    box-shadow: none;
}
.new-faq-list .accordion-button.collapsed::after {
    transform: rotate(270deg);
}
.new-faq-list .accordion-button:not(.collapsed)::after {
    transform: rotate(0deg);
}
.new-faq-list .accordion-item {
    border: none;
    border-bottom: 1px solid #000;
    border-radius: 0;
    padding: 20px 0px;
}
.new-faq-list .accordion-body {
    padding: 12px 0px 0px;
    padding-top: 12px;
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.new-faq-list .accordion-body p {
    margin: 0;
    color: var(--text-black);
    text-align: right;
    font-family: var(--assistant);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; 
}
.new-faq-list .accordion-body p a {
    font-weight: 500;
    margin: 0;
    color: var(--text-black);
}
.new-faq-list .accordion-body ol, .faq-list .accordion-body ul {
    margin: 0;
}
.new-faq-list .accordion-body li {
   padding-bottom: 5px;
}
.new-faq-list .accordion-button::after {
    background-image: url("../../assets/images/new/down-arrow.svg");
    background-size: 24px;
}

.rtl .new-faq-list-section {
    direction: rtl;
}
.rtl .accordion-button::after {
    margin-right: auto;
    margin-left: unset;
}
.rtl .new-faq-list .accordion-button.collapsed::after {
    transform: rotate(90deg);
}
.still-que-grid h3 {
    color: var(--text-black);
    text-align: center;
    font-family: var(--assistant);
    font-size: 42px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    letter-spacing: 3.36px;
    margin: 0;
}
.rtl .still-que-grid h3 {
    direction: rtl;
}
.still-que-grid p {
    color: var(--text-black);
    text-align: center;
    font-family: var(--assistant);
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin: 0;
    padding-top: 16px;
    padding-bottom: 24px;
}
.still-que-grid .border-btn {
    max-width: 163px;
    height: 59px;
    margin: 0px auto !important;
}

/* ************************************** Contact section ****************************************** */
.contact-section {
    padding: 112px 0px;
    background: var(--foundation-btn-color);
}
.new-contact-title h2 {
    color: var(--text-black);
    text-align: center;
    font-family: var(--assistant);
    font-size: 42px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: 3.36px;
    margin: 0;
    padding-bottom: 24px;
}
.new-contact-title p {
    color: var(--text-black);
    text-align: center;
    font-family: var(--assistant);
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin: 0;
}
.new-contact-form {
    max-width: 560px;
    width: 100%;
    margin: 0px auto;
    margin-top: 40px;
}
.form-group-grid {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.form-group-grid .form-group {
    width: 48%;
}
.rtl .form-group-grid {
    direction: rtl;
}
.new-contact-form .form-group {
    padding-bottom: 24px;
}
.new-contact-form  .form-label {
    color: var(--text-black);
    text-align: right;
    font-family: var(--assistant);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    margin: 0;
    padding-bottom: 8px;
}

.new-contact-form .form-control {
    border: 1px solid var(--text-black);
    background:  #FFF;
    color:  #505050;
    text-align: right;
    font-family: var(--assistant);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; 
    padding: 12px;
    height: 48px;
    width: 100%;
    border-radius: 0;
}
.new-contact-form .form-control::placeholder {
    color:  #505050;
}
.new-contact-form .form-control:focus {
    box-shadow: none;
}
.new-contact-form .react-tel-input .form-control {
    padding: 12px 55px;  
}
.rtl .new-contact-form .react-tel-input .form-control {
    padding-left: 12px;
}
.rtl .new-contact-form .react-tel-input .flag-dropdown {
    left: 0px;
    direction: rtl;
}
.rtl .new-contact-form .react-tel-input .selected-flag {
    padding: 0 11px 0 11px;
}
.rtl .new-contact-form .react-tel-input .selected-flag .arrow {
    right: 30px;
    left: unset;
}
.react-tel-input .selected-flag:focus:before, .react-tel-input .selected-flag.open:before {
    border-color: transparent;
    box-shadow: none;
}
.new-contact-form textarea.form-control {
    height: auto;
    border-radius: 0;
    text-align: unset;
}
.new-contact-form .form-check {
    direction: rtl;
}
.new-contact-form .form-check .form-check-input {
    border: 1px solid var(--text-black);
    /* background:  #FFF; */
    border-radius: 0;
}
.new-contact-form .form-check label {
    color: var(--text-black);
    font-family: var(--assistant);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
}
.rtl .new-contact-form .form-check .form-check-input {
    float: right;
    margin-left: 8px;
}
.new-contact-form .black-btn {
    max-width: 185px;
    height: 59px;
    margin: 0px auto;
    font-size: 18px !important; 
    font-style: normal;
    font-weight: 700 !important;
}

.new-info-modal .modal-header {
    border-bottom: none;
}
.new-info-modal .modal-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
}
.new-info-modal .modal-body img, .new-info-modal .modal-body svg {
    width: 80px;
    height: 80px;
}
.success-icon-grid svg path {
    stroke: #1E0F3C;
}
.error-icon-grid svg path {
    fill: linear-gradient(343deg, #FF0F7B 0%, #F89B29 100%);
}
.new-info-modal .modal-body h6 {
    color: var(--text-black);
    font-family: var(--assistant);
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; 
    letter-spacing: 3.36px;
    margin: 0;
    text-align: center;
    padding-bottom: 20px;
}

.new-info-modal .modal-footer {
    border: none;
    justify-content: center;
    padding: 0;
    padding-bottom: 20px;
}
.new-info-modal .modal-footer .border-btn {
   max-width: 160px;
}
.stats-box mask, .stats-box rect {
    transition: all 2000ms;
}
.reel-grid * {
    font-family: var(--assistant) !important;
}
/*************** Media Query ******************/
@media screen and (max-width: 1440px) {  
    .how-works-left {
        max-width: 48%;
        width: 100%;
    }
    .how-works-right {
        max-width: 48%;
        width: 100%;
    }
}

@media screen and (max-width: 1024px) { 
    .tab-view-hide {
        display: none;
    }
    .new-hero-left {
        max-width: 48%;
    }
    .new-hero-right {
        max-width: 48%;
    }
    .utm-banner-section span {
        font-size: 24px;
    }
    .how-works-grid {
        gap: 40px;
        justify-content: center;
    }
    .how-works-left {
        width: 100%;
    }
    .how-works-right {
        width: 100%;
        max-width: 616px;
        margin: 0px auto;
    }
    .how-works-left img {
        height: 400px;
        width: 100%;
        object-fit: cover;
    }
    .testimonial-grid {
        display: flex;
        flex-wrap: wrap;
    }
}
@media screen and (max-width: 991px) {
    .new-hero-grid {
        padding-top: 84px;
        padding-bottom: 60px;
        row-gap: 24px;
    }
    .hero-main-heading h1 {
        text-align: center;
        margin: 0px auto;
    }
    .hero-main-heading p {
        text-align: center;
    }
    .new-hero-right {
        max-width: 100%;
    }
    .search-input {
        max-width: 400px;
        width: 100%;
        margin: 0px auto;
    }
    .new-hero-left {
        order: 1;
        max-width: 100%;
        width: 100%;
        padding: 0;
        border: none;
    }
    .new-hero-left img {
        width: 100%;
    }
    .hero-cta-section, .rtl .hero-cta-section  {
        justify-content: center;
        padding-top: 24px;
    }
    .utm-banner-section span {
        font-size: 18px;
    }
    .our-feature {
        padding: 60px 0px;
    }
    .feature-main-grid {
        row-gap: 24px;
    }
    .features-box svg, .features-box img {
        width: 90px;
        height: 98px;
    }
    .features-box h3 {
        padding: 0;
    }
    .features-grid {
        row-gap: 32px;
        grid-template-columns: repeat(2,minmax(0,1fr));
    }
    .stats-section {
        padding: 60px 0px;
    }
    .stats-main-grid {
        padding-bottom: 40px;
    }
    .stats-grid {
        padding-top: 24px;
        grid-template-columns: repeat(2,minmax(0,1fr));
    }
    .stats-box:first-child,  .stats-box:nth-child(2) {
        order: 2;
    }
    .stats-box img, .stats-box svg {
        width: 90px;
    }
    .stats-box p {
        font-size: 18px;
        letter-spacing: 0.36px;
    }
    .stats-box h4 {
        font-size: 34px;
        letter-spacing: 1.36px;
    }
    .how-works {
        padding: 60px 0px;
    }
    .how-works-grid {
        justify-content: center;
        padding: 24px 0px;
        gap: 40px;
    }
    .how-works-left {
        width: 100%;
        max-width: 100%;
    }
    .how-works-left img {
       height: 400px;
       width: 100%;
       object-fit: cover;
    }
    .how-works-box {
        padding-right: 16px;
    }
    .how-works-box-grid {
        gap: 16px;
    }
    .how-works-right {
        gap: 24px;
    }
    .how-works-box-right {
        padding-left: 16px;
    }
    .how-works-box-right h6 {
        font-size: 18px;
    }
    .how-works-box-right p {
        font-size: 16px;
    }
    .how-works-box-grid::after {
        width: 3px;
    }
    .medicine-slider-section {
        padding-bottom: 24px;
    }
    .medicine-setion-title {
        padding: 0px 24px;
    }
    .medicine-setion-title h2 {
        font-size: 24px;
        letter-spacing: 1.92px;
    }
    .medicine-setion-title p {
        font-size: 18px;
    }
    .medicine-slider-logo {
        min-height: 110px;
        padding: 0px 15px;
    }
    .medicine-slider-logo img {
        height: 100px;
        /* transform: scale(.8); */
    }
    .testimonial-section {
        padding: 60px 0px;
    }
    .testimonial-grid {
        padding: 24px 0px;
        gap: 16px;
        justify-content: space-between;
    }
    .testimonial-sub-grid {
        gap: 16px;
        width: 48%;
        align-items: center;
    }
    .testimonial-box {
        max-width: 100%;
        padding: 16px;
        gap: 24px;
    }
    .new-our-expert {
        padding: 60px 0px;
    }
    .new-expert-list ul {
        padding-top: 24px;
        width: 100%;
        gap: 10px;
    }
    .new-expert-list li {
        width: 47%;
        gap: 4px;
        justify-content: flex-start;
    }
    .new-expert-list li svg {
        height: 24px;
    }
    .new-expert-slider {
        padding-top: 24px;
    }
    .new-expert-profile {
        height: 270px;
    }
    .new-our-expert .feature-cta, .doctor-testimonial-section .feature-cta {
        padding-top: 24px;
    }
    .faq-section {
        padding: 60px 0px;
    }
    .new-faq-list-section {
        max-width: 550px;
    }
    .new-faq-list .accordion-button {
        column-gap: 10px;
    }
    .contact-section {
        padding: 60px 0px;
    }
    .new-contact-title h2 {
        padding-bottom: 24px;
    }
    .new-contact-form {
        margin-top: 24px;
    }
    .testimonial-box.first-line {
        height: 471px;
    }
    .testimonial-box.second-line {
        height: 255px;
    }
}
  
  
  @media screen and (max-width: 767px) {
    .hero-main-heading h1 {
        font-size: 38px;
        letter-spacing: 3.04px;
        text-align: right;
    }
    .hero-main-heading p {
        font-size: 18px;
        text-align: right;
    }
    .search-input {
        max-width: 100%;
    }
    .rtl .hero-cta-section {
        justify-content: flex-end;
    }
    .utm-banner-section span {
        font-size: 14px;
    }
    .feature-title h2 {
        font-size: 24px;
        letter-spacing: 1.92px;
    }
    .features-box {
        display: flex;
        direction: rtl;
        gap: 32px;
        text-align: right;
    }
    .features-box h3 {
        text-align: right;
        font-size: 18px;
        letter-spacing: 0.72px;
    }
    .features-box p {
        text-align: right;
        font-size: 16px;
        letter-spacing: 0.32px;
        padding-top: 8px;
    }
    .stats-title h2 {
        font-size: 24px;
        letter-spacing: 1.92px;
        max-width: 162px;
        width: 100%;
        margin: 0px auto;
    }
    .features-grid {
        grid-template-columns: repeat(1,minmax(0,1fr));
    }
    .how-works-left img {
        height: 250px;
    }
    .testimonial-title h2 {
        font-size: 24px;
        letter-spacing: 1.92px;
    }
    .testimonial-box {
        max-width: 342px;
    }
    .mobile-view-hide {
        display: none;
    }
    .new-expert-title h2 {
        font-size: 24px;
        letter-spacing: 1.92px;
    }
    .new-faq-title h2 {
        font-size: 24px;
        letter-spacing: 1.92px;
    }
    .new-faq-title p {
        font-size: 18px;
    }
    .still-que-grid h3 {
        font-size: 24px;
        letter-spacing: 1.92px;
    }
    .still-que-grid p {
        font-size: 18px;
        padding-top: 8px;
    }
    .form-group-grid .form-group {
        width: 100%;
    }
    .tab-view-hide {
        display: block;
    }
    .testimonial-box.first-line {
        height: fit-content;
    }
    .testimonial-box.first-line .testimonial-name {
        position: unset;
        transform: unset;
    }
    .stats-box {
        min-width: 148px;
    }
    .reel-grid {
        padding: 0;
    }
  }
  @media screen and (max-width: 540px) {
    .testimonial-sub-grid {
        width: 100%;
    }
   }
   @media screen and (max-width: 480px) {
    .new-auth-box {
        margin-right: 60px;
    }
    .new-auth-box .border-btn {
        font-size: 14px !important;
        padding: 10px !important;
        background: var(--foundation-violet) !important;
        color: var(--foundation-btn-color) !important;
    }
   }