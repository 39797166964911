.pharmacies-list-page {
  padding: 80px 0px;
}
.pharmacies-list-title {
  padding-bottom: 80px;
}
.pharmacies-list-title h2 {
  color: #1e0f3c;
  font-family: var(--assistant);
  font-size: 42px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: 3.36px;
  margin: 0;
  text-align: center;
}
.pharmacies-list-content {
  padding-top: 60px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-width: 70%;
  width: 100%;
  margin: 0px auto;
}
.custom-row {
  display: grid;
  grid-template-columns:  1fr 0.5fr 0.8fr  0.3fr;
}

.custom-head h6 {
  font-family: var(--assistant) !important;
  color: #1e0f3c;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  margin: 0;
}
.custom-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.custom-body h6 {
  font-family: var(--assistant) !important;
  color: #1e0f3c;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  margin: 0;
}
ul.pharmacies-list-grid {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-top: 80px;
}
.pharmacies-list-grid li {
  font-family: var(--assistant) !important;
  color: #1e0f3c;
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  margin: 0;
  list-style: none;
}
@media screen and (max-width: 991px) {
  .pharmacies-list-page {
    padding: 60px 0px;
  }
  .pharmacies-list-title {
    padding-bottom: 24px;
  }
  ul.pharmacies-list-grid {
    padding-top: 40px;
  }
  .pharmacies-list-content {
    max-width: 100%;
    padding-top: 30px;
    gap: 10px;
    overflow: auto;
  }
  .custom-row {
    grid-template-columns: 1.2fr 0.6fr 1.2fr 0.5fr;
    gap: 10px;
  }
  .custom-head h6 {
    font-size: 16px;
  }
  .custom-body h6 {
    font-size: 14px;
    text-wrap: nowrap;
  }
}

@media screen and (max-width: 767px) {
  .custom-row {
    display: flex;
  }
  .custom-body h6:first-child, .custom-head h6:first-child {
    min-width: 170px;
  }
  .custom-body h6:nth-child(2), .custom-head h6:nth-child(2) {
    min-width: 90px;
  }
  .custom-body h6:nth-child(3), .custom-head h6:nth-child(3) {
    min-width: 160px;
  }
  .custom-body h6:nth-child(4), .custom-head h6:nth-child(4) {
    min-width: 80px;
  }
  .pharmacies-list-title h2 {
    font-size: 24px;
    letter-spacing: 1.92px;
  }
  ul.pharmacies-list-grid {
    gap: 12px;
  }
  .pharmacies-list-grid li {
    font-size: 16px;
  }
}
