.faq-page {
    padding: 80px 0px;
}
.faq-title h1 {
    color: #1E0F3C;
    text-align: center;
    font-family: var(--assistant);
    font-size: 42px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: 3.36px;
    margin: 0;
    padding-bottom: 20px;
}
.faq-title p {
    color: var(--text-black);
    text-align: center;
    font-family: var(--assistant);
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.36px;
    max-width: 920px;
    margin: 0px auto;
}

.faq-btn-grid {
    padding-top: 80px;
}

.faq-btn-grid .black-btn {
    max-width: 278px;
    height: 59px;
    margin: 0px auto;
}


/*************** Media Query ******************/

@media screen and (max-width:991px) { 
    .faq-page {
        padding: 60px 0px;
    }
}

@media screen and (max-width:767px) { 
    .faq-btn-grid {
        padding-top: 40px;
    }
    .faq-title h1 {
        letter-spacing: 1.92px;
    }
}