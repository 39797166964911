.about {
    padding: 80px 0px;
}
.about h2 {
    color: #1E0F3C;
    text-align: center;
    font-family: var(--assistant);
    font-size: 42px;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: 3.36px;
    margin: 0;
}
.about-video {
    max-width: 650px;
    width: 100%;
    margin: 0px auto;
}
  
.about-video-iframe {
    width: 100%;
    height: 450px;
    border-radius: 0.25em 0.25em 0.4em 0.4em;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}
.about-description {
    padding-top: 30px;
}
.about-description h1 {
    color: var(--text-black);
    text-align: center;
    font-family: var(--assistant);
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 33.6px */
    letter-spacing: 0.96px;
    margin: 0;
    padding-bottom: 30px;
}
.about-description p {
    color:  #685E7C;
    text-align: right;
    font-family: var(--assistant);
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    margin: 0;
    padding-bottom: 20px;
}
.rtl .about-content {
    direction: rtl;
}

/*************** Media Query ******************/

@media screen and (max-width:991px) { 
    .about {
        padding: 60px 0px;
    }
    .about-video-iframe {
        height: 400px;
    }
}
@media screen and (max-width:767px) { 
    .about h2 {
        letter-spacing: 1.92px;
    }
    .about-video-iframe {
        height: 300px;
    }
    .about-description h1 {
        font-weight: 600;
        padding-bottom: 20px;
    }
    .about-description p {
        font-size: 22px;
        padding-bottom: 10px;
    }
}