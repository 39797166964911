.new-header-wrapper {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 99;
}
.top-banner-grid span {
    display: inline-block;
    background: red;
    width: 100%;
    cursor: pointer;
}
.new-header {
    background: #FFF;
    box-shadow: 0px -1.0000001192092896px 0px 0px #000 inset;
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.new-header-grid {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
}
.new-menu-section {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    max-width: calc(100% - 620px);
    width: 100%;
}
.new-auth-box .black-btn {
    max-width: 190px;
}
 .new-auth-box .border-btn {
    max-width: 165px;
}
.new-header-menu .navbar-expand-lg .navbar-nav .nav-link {
    color: var(--text-black);
    font-family: var(--roboto);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    padding: 0px 16px;
}
.rtl .new-header-menu .navbar-expand-lg .navbar-nav .nav-link {
    direction: rtl;
}
.new-header-menu .navbar-toggler,
.new-header-menu .navbar-toggler:focus {
    background: transparent;
    box-shadow: none;
    border: none;
    padding: 0;
    width: 40px;
    height: 40px;
}
.new-header-menu .navbar-expand-lg .navbar-nav .nav-link.mobile-view-link {
    display: none;
}
.new-auth-box {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
    max-width: 425px;
    width: 100%;
}

.lang-dropdown.dropdown {
    display: none;
}

.lang-dropdown.dropdown .btn-success {
    background: transparent;
    border: transparent;
    font-size: 20px;
    padding: 0;
    display: flex;
    align-items: center;
    gap: 2px;
    color: var(--text-black);
    font-family: var(--roboto);
}
.lang-dropdown.dropdown .btn-success img {
    width: 20px;
    height: 20px;
}
.dropdown-menu {
    padding: 4px 0px;
}
.lang-dropdown.dropdown .dropdown-item {
    display: flex;
    gap: 10px;
    align-items: center;
    padding: 5px 10px;
    font-size: 14px;
    font-weight: 600;
    color: var(--text-black);
    font-family: var(--assistant);
}
.lang-dropdown.dropdown .dropdown-item.active, .lang-dropdown.dropdown .dropdown-item:active {
    background: transparent;
}
.lang-dropdown.dropdown .dropdown-item img {
    width: 20px;
    height: 20px;
}

/*************** Media Query ******************/


@media screen and (max-width:991px) {

    .new-header-menu {
        max-width: none;
        position: absolute;
        right: 20px;
        width: auto;
    }
    .new-auth-box {
        margin-right: 67px;
    }
    .new-header-menu .navbar {
        padding: 0;
        width: 40px;
        height: 40px;
    }
    .new-header-menu #responsive-navbar-nav {
        background: var(--foundation-violet);
        box-shadow: 0 4px 31px rgba(35, 40, 59, .1);
        height: 100vh;
        left: -315px;
        max-width: 315px;
        padding: 0;
        position: fixed;
        top: 0;
        transition: all .3s ease 0s;
        width: 100%;
        z-index: 999999;
    }
    .new-header-menu .navbar-collapse.collapse.show {
        left: 0 !important;
    }
    .new-header-menu .navbar-toggler.collapsed .navbar-toggler-icon {
        background-image: url("../../assets/images/new/menu-icon.svg");
        transition: all .5s;
        width: 40px;
    }
    .new-header-menu .navbar-toggler .navbar-toggler-icon {
        background-image: url("../../assets/images/new/menu-close.svg");
        width: 40px;
    }
    .navbar-nav {
        padding: 25px 20px;
    }
    .new-header-menu .navbar-expand-lg .navbar-nav .nav-link {
        color:  #E9E7EC;
        font-family: var(--roboto);
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
        border-bottom: 1px solid rgb(250 101 72);
        padding-bottom: 10px;
    }
    .new-header-menu .navbar-expand-lg .navbar-nav {
        gap: 20px;
    }
    .new-header-menu .navbar-expand-lg .navbar-nav .nav-link.mobile-view-link {
        border: none;
        display: block;
    }
    a.nav-link.mobile-view-link svg {
        width: 120px;
        height: 30px;
    }
    .mobile-view-btn.mobile-view {
        display: none;
    }
}

@media screen and (max-width:767px) { 
    .header .logo {
        width: 90px;
    }
    .new-auth-box .black-btn {
        display: none !important;
    }
    .mobile-view-btn.mobile-view {
        display: flex;
        gap: 20px;
        flex-direction: column;
        align-items: center;
        padding-top: 40px;
    }
    .mobile-view-btn .border-btn {
        border: 1px solid rgb(250 101 72) !important;
        color: rgb(250 101 72) !important;
        max-width: 195px;
    }
    .mobile-view-btn .black-btn {
        background: rgb(250 101 72) !important;
        color: var(--text-black) !important;
        font-weight: 700 !important;
        max-width: 195px;
    }
   
}
@media screen and (max-width: 320px) { 
    .new-header-grid {
        gap: 8px;
    }
    .new-header-menu #responsive-navbar-nav {
        max-width: 260px;
    }
  }