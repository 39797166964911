.rx-detail * {
    margin: 0;
}
.rx-detail .container {
    margin: 0px auto;
}
.rx-detail .black-btn {
    height: 42px;
    transition: all 0.5s;
    transition-duration: .3s;
    transition-property: transform;
    font-size: 18px !important;
    font-weight: 700 !important;
    width: auto;
    line-height: 1 !important;
    padding: 12px 24px !important;
    
}
.rx-detail .black-btn:hover {
    transform: scale(1.1);
}
.rx-logo {
    padding: 10px 0px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.rx-logo img {
    max-width: 100%;
    width: 18.5%;
    height: auto;
}

.rx-hero-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 20px 0px 40px 0px;
}
.rx-hero-content h2 {
    color: #1C0D38;
    font-family: var(--assistant);
    font-size: 60px;
    font-weight: 700;
    line-height: 1;
}
.rx-hero-content h3 {
    color: #1C0D38;
    font-family: var(--assistant);
    font-size: 60px;
    font-weight: 600;
    line-height: 1;
}
.rx-hero-content h4 {
    color: #1C0D38;
    text-align: center;
    font-family: var(--assistant);
    font-size: 30px;
    font-weight: 600;
    line-height: 1.2;
    padding: 8px 0px 16px 0px;
}

.smooth-heading {
    display: flex;
    white-space: nowrap; 
}
.dynamic-text-grid {
    position: relative;
    perspective: 300px;
    display: inline-block;
}
.dynamic-text-grid span {
    display: inline-block;
}
  
.fades {
    color: #1C0D38;
    position: absolute;
    right: 0;
    top: 0;
}

.fade-in {
    opacity: 1;
    animation: elementor-headline-drop-in-in .8s;
}

.fade-out {
    opacity: 0;
    animation: elementor-headline-drop-in-out .8s;
}
@keyframes elementor-headline-drop-in-in {
    0% {
        opacity: 0;
        transform: scale(0.9)
    }

    to {
        opacity: 1;
        transform: scale(1)
    }
}

@keyframes elementor-headline-drop-in-out {
    0% {
        opacity: 1;
        transform: scale(1)
    }

    to {
        opacity: 0;
        transform: scale(0.8)
    }
}

.right-text {
    display: inline-block;
    transition: transform 0.8s ease-in-out;
}

.smooth-heading .fade-out ~ .right-text {
    transform: translateX(-10px); 
}

.smooth-heading .fade-in ~ .right-text {
    transform: translateX(0); 
}

.rx-banner {
    background: #BAB5C2;
}
.rx-banner-content {
    padding: 20px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}
.rx-banner-content h2 {
    color: #1C0D38;
    font-family: var(--assistant);
    font-size: 40px;
    font-weight: 700;
    line-height: 1;
}
.rx-banner-content p {
    text-align: center;
    color: #1C0D38;
    font-family: var(--assistant);
    font-size: 26px;
    font-weight: 400;
    padding-bottom: 14px;
    max-width: 890px;
    width: 100%;
}
.rx-banner-content p span {
    font-weight: 700;
}
.heart-logo {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.heart-logo img {
    max-width: 100%;
    width: 4.5%;
    height: auto;
}
.why-rx-content {
    padding: 40px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-direction: column;
    max-width: 1140px;
    width: 100%;
    margin: 0px auto;
}

.why-rx-content h2 {
    color: #1C0D38;
    font-family: var(--assistant);
    font-size: 40px;
    font-weight: 700;
    line-height: 1;
}
.why-rx-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    column-gap: 20px;
    row-gap: 30px;
    padding-top: 10px;
    padding-bottom: 30px;
}
.why-rx-box {
    max-width: calc(100% / 3 - 20px);
    width: 100%;
    padding: 0px 20px;
}

.why-rx-icon {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.why-rx-icon img {
    max-width: 100%;
    width: 30%;
    height: auto;
}
.why-rx-box h3 {
    color: #1C0D38;
    font-family: var(--assistant);
    font-weight: 700;
    font-size: 1.75rem;
    padding-top: 13px;
    padding-bottom: 4px;
    line-height: 1.2;
    text-align: center;
}
.why-rx-box p {
    color: #1C0D38;
    font-family: var(--assistant);
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
}

.rx-faq {
    background: #E8E8ED;
    padding: 20px 0px;
}
.rx-faq-content {
    display: flex;
    justify-content: center;
    /* align-items: center; */
    flex-direction: column;
    gap: 10px;
    max-width: 1140px;
    width: 100%;
    margin: 0px auto;
}
.rx-faq-content .new-faq-title h2 {
    font-weight: 700;
    font-size: 40px;
    color: #1C0D38;
    padding: 0;
    line-height: 1;
    letter-spacing: unset;
}
.rx-faq-content .new-faq-list-section {
    max-width: 1120px;
    width: 100%;
    border: none;
}
.rx-faq-content .new-faq-list .accordion-item {
    border: none;
    background: transparent;
    padding: 0px;
}
.rx-faq-content .new-faq-list .accordion-button {
    gap: 10px;
    line-height: 1.5;
    font-weight: 600;
    color: #1C0D38;
    padding: 10px 0px;
}

.rx-faq-content .new-faq-list .accordion-button.collapsed::after {
    display: none;
}
.rx-faq-content .new-faq-list .accordion-button:not(.collapsed)::after {
    display: none;
}
.rx-icon {
    width: 13px;
    height: 15px;
    transition: all 0.5s;
}

.open-icon, .close-icon {
    display: none;
}

.rx-faq-content .new-faq-list .accordion-button:not(.collapsed) .close-icon {
    display: flex;
}

.rx-faq-content .new-faq-list .accordion-button.collapsed .open-icon {
    display: flex;
}
.rx-faq-content .new-faq-list .accordion-body {
    padding-top: 10px;
    padding-bottom: 24px;
}
.rx-faq-content .new-faq-list .accordion-body p {
    color: #1C0D38;
    line-height: 1.5;
}

.rx-testimonial-section {
    padding: 40px 0px;
}
.comma-one {
    width: 95%;
}
.comma-one svg, .comma-two svg {
    width: 50px;
    height: 50px;
    fill: #e8e8ed;
}
.comma-two {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 95%;
    padding-top: 20px;
}
.rx-testimonial-content {
    max-width: 1140px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    margin: 0px auto;
}
.rx-testimonial-content .container {
    padding: 0px !important;
}
.rx-testimonial-content .testimonial-title h2 {
    font-size: 40px;
    font-weight: 700;
    letter-spacing: unset;
    line-height: 1;
}
.rx-slider-grid {
    max-width: calc(50% - 0px);
    padding: 0px 20px;
    width: 100%;
    margin: 0px auto;
}
.rx-slide {
    padding: 20px;
}

.rx-slide-content {
    background-color: #E8E8ED;
    padding: 20px;
    position: relative;
}
.rx-slide-content:after {
    background-color: #E8E8ED;
    left: 50%;
    transform: translateX(-50%) scaleX(.75) rotate(45deg);
    top: calc(100% - 7px);
    content: "";
    display: block;
    width: 14px;
    height: 14px;
    position: absolute;
    border-width: 0;
    box-sizing: content-box;
}

.rx-slide-content h6 {
    font-size: 1.3em;
    font-style: italic;
    line-height: 1.5;
    color: #7a7a7a;
    font-weight: 400;
    font-family: var(--assistant);
    text-align: center;
    direction: rtl;
}
.rx-slide-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 25px 20px 0px 20px;
}

.rx-slide-footer h6 {
    color: #1C0D38;
    font-family: var(--assistant);
    font-size: 18px;
    font-weight: 600;
    line-height: 1.5;
}
.rx-slide-footer p {
    color: #1C0D38;
    font-family: var(--assistant);
    font-size: 18px;
    font-weight: 400;
    line-height: 1.5;
}

.rx-slider-grid .slick-prev, .rx-slider-grid .slick-next {
    width: 20px;
    height: 20px;
    z-index: 9;
}
.rx-slider-grid .slick-prev {
    background: url("../../assets/images/rx/arrow-left.svg") no-repeat center transparent;
    left: -20px;
    background-size: cover;
}
.rx-slider-grid .slick-next {
    background: url("../../assets/images/rx/arrow-right.svg") no-repeat center transparent;
    right: -20px;
    background-size: cover;
}
.rx-slider-grid .slick-prev:before , .rx-slider-grid .slick-next:before {
    content: unset;
}

.rx-slider-grid .slick-dots li {
    margin: 0;
}
.rx-contact-section {
    padding: 40px 0px;
    background: #E8E8ED;
}
.rx-contact-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
}
.rx-contact-content .new-contact-form {
    max-width: 750px;
}
.rx-contact-content .new-contact-title h2 {
    color: #1C0D38;
    font-size: 40px;
    font-weight: 700;
    line-height: 1;
    padding-bottom: 10px;
    font-family: var(--assistant);
}
.rx-contact-content .new-contact-title p {
    color: #1C0D38;
    font-size: 19px;
    font-weight: 500;
    line-height: 26px;
    padding-bottom: 14px;
    text-align: right;
    max-width: 1140px;
}
.rx-contact-content .new-contact-title p span {
    text-decoration: underline;
}
.rx-contact-content .new-contact-form .form-control {
    color: #1C0D38;
    border: 1px solid #69727d;
}
.rx-contact-content .new-contact-form .form-check .form-check-input {
    min-width: 16px;
}
.rx-contact-content .new-contact-form .form-check label {
    color: #54595F;
    line-height: 1;
}
.rx-contact-content .form-check {
    display: flex;
    padding: 0;
}
.rx-contact-content .new-contact-form .black-btn {
    width: 100%;
}

.rx-footer {
    background: #1C0D38;
    padding: 20px 0px;
}
.rx-footer-content h2 {
    color: #FFFFFF;
    font-family: var(--assistant);
    font-size: 20px;
    font-weight: 400;
    line-height: 1;
    text-align: center;
}

@media screen and (max-width:991px) {
    .why-rx-grid {
        column-gap: 15px;
    }
    .why-rx-box {
        max-width: calc(100% / 3 - 10px);
        padding: 0px 15px;
    }
    .why-rx-box h3 {
        font-size: 20px;
    }
    .why-rx-box p {
        font-size: 18px;
    }
    .rx-slider-grid {
        max-width: 100%;
    }
}
@media screen and (max-width: 767px) {
    .rx-logo img {
        width: 46%;
    }
    .rx-hero-content h2 {
        font-size: 40px;
    }
    .rx-hero-content h3 {
        font-size: 40px;
        text-align: center;
    }
    .rx-hero-content h4 {
        font-size: 24px;
    }
    .rx-banner-content h2 {
        font-size: 28px;
        text-align: center;
    }
    .rx-banner-content p {
        font-size: 20px;
    }
    .heart-logo img {
        width: 13%;
    }
    .why-rx-content h2 {
        font-size: 28px;
    }
    .why-rx-grid {
        row-gap: 15px;
    }
    .why-rx-box {
        max-width: 100%;
        padding: 0px 13px;
    }
    .why-rx-box p {
        font-size: 20px;
    }
    .rx-faq-content .new-faq-title h2 {
        font-size: 28px;
    }
    .rx-faq-content .new-faq-list .accordion-button {
        font-size: 16px;
    }
    .rx-faq-content .new-faq-list .accordion-body {
        padding-bottom: 16px;
    }
    .rx-faq-content .new-faq-list .accordion-body p {
        font-size: 16px;
    }
    .rx-testimonial-content .testimonial-title h2 {
        font-size: 28px;
    }
    .comma-one {
        width: 100%;
        text-align: center;
    } 
    .comma-two {
        justify-content: center;
    }
    .rx-slider-grid {
        padding: 0px;
    }
    .rx-slide {
        padding: 0px 5px;
    }
    .rx-contact-section {
        padding: 20px 0px;
    }
    .rx-contact-content .new-contact-title h2 {
        font-size: 28px;
    }
    .rx-contact-content .new-contact-title p {
        font-size: 16px;
    }
}